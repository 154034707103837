<template>
    <layout :hidecenter="true">
        <v-container class="loginpage mb-16">
            <breadcrumbs :items="breadcrumbs" :large="true" class="py-7" textcolor="textdark"></breadcrumbs>
            <v-form
                ref="form"
                v-model="valid"
                lazy-validation
            >
                <v-card flat>
                    <v-row>
                        <v-col cols="12" lg="7">
                            <v-card-text class="px-md-10 py-8">
                                <h1 class="text-28-60">Log in to continue</h1>
                                <p class="pt-4 mb-8">Don't have an account? <router-link :to="{ name: 'frontend.auth.register', params: {} }">Sign up</router-link></p>
                                <v-btn x-large class="bg-transparent py-8 text-14 text-transform-none w-100 googlelogin" depressed :ripple="false" @click="AuthProvider('google')">
                                    <div class="d-flex justify-space-between w-100">
                                        <img src="@/assets/icons/auth/google.svg" alt="">
                                        <span>Log in with Google</span>
                                        <span></span>
                                    </div>
                                </v-btn>
                                <div class="d-flex my-8 align-center">
                                    <div class="dotborder w-100"></div>
                                    <div class="flex-grow-1 flex-shrink-0 px-5">Or login with email</div>
                                    <div class="dotborder w-100"></div>
                                </div>
                                <v-snackbar v-model="snackbar" color="red">{{autherror}}</v-snackbar>
                                <div class="">
                                    <label for="">Email</label>
                                    <v-text-field
                                        class="mt-2"
                                        v-model="form.email"
                                        :rules="emailRules"
                                        placeholder="your@email.com"
                                        outlined
                                        background-color="silver"
                                        v-on:keyup.enter="logIn"
                                    ></v-text-field>
                                </div>
                                <div class="">
                                    <label for="">Password</label>
                                    <v-text-field
                                        class="mt-2"
                                        v-model="form.password"
                                        placeholder="Password"
                                        :rules="[v => (v && v.length >= 8) || 'Password must be more than 8 characters']"
                                        outlined background-color="silver"
                                        :append-icon="pass_show ? 'mdi-eye-off' : 'mdi-eye'"
                                        :type="pass_show ? 'text' : 'password'"
                                        @click:append="pass_show = !pass_show"
                                        v-on:keyup.enter="logIn"
                                    ></v-text-field>
                                </div>
                                <div class="d-flex flex-column flex-md-row justify-space-between">
                                    <v-checkbox v-model="form.remember" label="Keep me logged in" class="checkbox mt-0 pt-0"></v-checkbox>
                                    <router-link :to="{ name: 'frontend.auth.reset', params: {} }">Forgot your password?</router-link>
                                </div>
                                <v-btn x-large class="secondary py-8 text-14 text-transform-none w-100 mt-5" depressed @click="logIn">Log in</v-btn>
                            </v-card-text>
                        </v-col>
                        <v-col cols="12" lg="5" class="primary px-0 py-0 d-none d-md-flex">
                            <div class="p-relative w-100">
                                <div class="mt-16 pt-16 text-center rightblockimagetext">
                                    <h2 class="text-28-60 white--text">Don't have an account?</h2>
                                    <p class="white--text mt-5">Create an account to track <br/>all your shipments</p>
                                    <v-btn x-large class="bg-white-02 px-15 py-8 mt-5 white--text text-transform-none text-14-45" depressed :to="{name: 'frontend.auth.register'}">Sign up <v-icon>mdi-chevron-right</v-icon></v-btn>
                                </div>
                                <img src="@/assets/icons/auth/login.png" alt="" class="p-absolute b-0 l-0 w-100">
                            </div>
                        </v-col>
                    </v-row>
                </v-card>
            </v-form>
        </v-container>
    </layout>
</template>

<script>
import Vue from 'vue'
import Layout from '@/components/layouts/LightTopLayout.vue'
import Breadcrumbs from '@/components/base/Breadcrumbs.vue'
import VueSocialauth from 'vue-social-auth'

Vue.use(VueSocialauth, {
    providers: {
        google: {
            clientId: '359876116110-hd4f7kk50kbi8qg7ipqs3re482of2309.apps.googleusercontent.com',
            redirectUri: 'https://quirinnull.cf/account/dashboard' // Your client app URL
        }
    }
})

export default {
    components: {
        Layout,
        Breadcrumbs
    },
    data: () => ({
        form:{
            email: '',
            email_error: null,
            password: '',
            password_error: null,
            remember: false,
        },
        valid: true,
        emailRules: [
            v => !!v || 'E-mail is required',
            v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
        ],
        snackbar: false,
        autherror: '',
        pass_show: false,
        breadcrumbs: [
            {
                text: 'Home page',
                to: 'frontend.home',
                disabled: false,
            },
            {
                text: 'Login',
                disabled: true,
            }
        ]
    }),
    methods: {
        async logIn(){
            if(this.$refs.form.validate()){
                var data = await this.axios.post(process.env.VUE_APP_API + 'customers/v1/login', this.form)
                    .catch((error) => {
                        if(error.response){
                            if(error.response.data.errors.email)
                                this.form.email_error = error.response.data.errors.email[0]
                            if(error.response.data.errors.password)
                                this.form.email_error = error.response.data.errors.password[0]
                        }

                    });
                if(!data){
                    this.snackbar = true;
                    this.autherror = 'Incorect Email or Password';
                } else if(data.status == 200){

                    this.$store.dispatch('auth/saveToken', {
                        token: data.data.token,
                        remember: this.form.remember
                    });

                    await this.$store.dispatch('auth/fetchUser')

                    var redirect = 'account.dashboard'

                    if(this.$store.getters['auth/toroute']){
                        redirect = this.$store.getters['auth/toroute'];
                        this.$store.dispatch('auth/removeRoute')
                    }
                    this.$router.push({ name: redirect })
                }
            }
        },
        AuthProvider(provider) {
            var self = this

            this.$auth.authenticate(provider).then(response =>{
                self.SocialLogin(provider,response)
            }).catch(err => {
                console.log({err:err})
            })
        },
        SocialLogin(provider,response){
            this.$http.post(process.env.VUE_APP_API + 'customers/v1/sociallogin/'+provider,response).then(response => {
                console.log(response.data)
            }).catch(err => {
                console.log('error here');
                    console.log({err:err})
            })
        },
    }
}
</script>

<style lang="scss">
@import '@/assets/scss/_variables.scss';

.loginpage{
    .v-text-field{
        fieldset{
            border: none;
        }
    }
    .googlelogin.v-btn.bg-transparent{
        border: 1px solid #CDE0FF;
    }
    .googlelogin.v-btn.bg-transparent:hover{
        border: 1px solid #ededed;
    }
    .dotborder{
        border-bottom: 1px dashed #B9B9B9;
        height: 1px;
    }
    .bg-white-02{
        background-color: rgba(255,255,255,0.2)!important;
    }
    .rightblockimagetext{
        position: relative;
        z-index: 5;
    }
    .checkbox{
        .v-label{
            font-size: 14px;
            line-height: 20px;
            color: $dark;
        }
    }
}
</style>
